var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "about" },
    [
      _c("h1", [_vm._v("This is an about page")]),
      _c("p", [
        _vm._v(
          "It requires authentication based on the lack of an allowAnonymous meta flag in the route definition."
        )
      ]),
      _c(
        "b-button",
        { attrs: { variant: "danger" }, on: { click: _vm.logout } },
        [_vm._v("Log Out")]
      ),
      _c("hr"),
      _c("PlaidLink", { attrs: { "company-id": 1 } }),
      _c("hr"),
      _c("PlaidLink", {
        attrs: {
          "company-id": 1,
          "item-id": 2,
          "button-title": "Renew Plaid Auth",
          variant: "warning"
        }
      }),
      _c("pre", { staticClass: "text-left" }, [_vm._v(_vm._s(_vm.user))])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }